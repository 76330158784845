<i18n>
ru:
  birthdayGift: Подарок на день рождения
  history: История заказов
  notifications: Уведомления
  personalCabinet: Личный кабинет
  points: 'Ваши баллы: '
  productsForPoints: Блюда за баллы
  profile: Профиль
  showMore: Показать ещё
  yourTickets: Ваши билеты
  yourTicketDate: 'от {date}'
  yourTicketsTitle: >-
    1 билет = 1 шанс на победу в розыгрыше. Получай билет за каждую N рублей
    в чеке.
  yourTicketsTitleNoTickets: >-
    Чтобы принять участие в розыгрыше, просто соверши заказ и за каждую N
    рублей в чеке получи свой билет. Удачи!
ua:
  birthdayGift: Подарунок на день народження
  history: Історія замовлень
  notifications: Повідомлення
  personalCabinet: Особистий кабінет
  points: 'Ваші бали: '
  productsForPoints: Страви за бали
  profile: Профіль
  showMore: Показати більше
  yourTickets: Ваші квитки
  yourTicketDate: 'від {date}'
  yourTicketsTitle: >-
    1 квиток = 1 шанс на перемогу в розіграші. Отримайте квиток за кожні N
    грівен у чеку.
  yourTicketsTitleNoTickets: >-
    Щоб взяти участь у розіграші, просто зробіть замовлення та отримайте квиток
    за кожні N грівен у чеку. Удачі!
us:
  birthdayGift: Birthday gift
  history: Order history
  notifications: Notifications
  personalCabinet: Personal cabinet
  points: 'Your points: '
  productsForPoints: Products for points
  profile: Profile
  showMore: Show more
  yourTickets: Your tickets
  yourTicketDate: 'from {date}'
  yourTicketsTitle: >-
    1 ticket = 1 chance to win the raffle. Get a ticket for every $N in
    your receipt.
  yourTicketsTitleNoTickets: >-
    To participate in the raffle, simply place an order and get a ticket for
    every $N in your receipt. Good luck!
</i18n>

<template>
  <div class="v-mb-sm">
    <lazy-account-personal-cabinet-udun
      v-if="appConfig.VueSettingsPreRun.PersonalCabinetLayout === 'Udun'"
    />
  </div>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const accountStore = useAccountStore()
const menuStore = useMenuStore()

onMounted(async () => {
  await Promise.all([
    accountStore.initPointsHistory(),
    accountStore.loadBalance(),
    menuStore.initAccountPointsMenu()
  ])
})

function onNotLoggedInEvent(): void {
  navigateTo(`${appConfig.VueSettingsPreRun.Links.MainLink}?showLoginPopup=true`)
}

onBeforeMount(() => {
  if (
    accountStore.Profile.state &&
    accountStore.Profile.state !== 'loading' &&
    !accountStore.isLoggedIn
  )
    onNotLoggedInEvent()
})

watch(
  () => accountStore.Profile.state,
  (newState, oldState) => {
    if (
      newState &&
      newState !== 'loading' &&
      newState !== oldState &&
      !accountStore.isLoggedIn
    )
      onNotLoggedInEvent()
  }
)
</script>
